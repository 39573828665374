import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment';
import $ from 'jquery';
import Alert from "sweetalert2";
import VideoPlayer from '../videoPlayer/VideoPlayer';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls } from '../../redux/reducers/reducer';
import { location } from '../../utils/utils';
import SelectionsExample from '../SelectionsExample'
import SweetAlert from 'react-bootstrap-sweetalert';
import asyncLoop from "node-async-loop";
import Header from '../authentication/Header'
import Footer from '../authentication/Footer'
import Loader from "../Loader";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./calender.css";
import './date.css';
import { assetInfo } from '../../redux/reducers/Actions';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
let urlParams = location("channelCode");
let imgUrl = '';
let eventFormatDuration = '';
let siteSettings = window.site;
let appSettings = window.app
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let user_id = localStorage.getItem('user_id');
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let siteConfig = siteSettings && siteSettings.config;
let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
class Scheduling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekendsVisible: true,
            currentEvents: [],
            calendarEvents: [],
            events: [],
            activity: 'fetch',
            activeTab: '1',
            isOpen: false,
            assetData: {},
            assetCount: 12,
            pageCount: 12,
            pageNumber: 1,
            assetsData: [],
            loadbtn: "Load More",
            assetTotal: 0,
            noOfAssets: 0,
            isLoading: true,
            isAddScheduleOpen: false,
            showAlert: false,
            errors: {},
            errorMsg: '',
            isMetaDataReady: false,
            eventsData: []
        }
        this.calendarComponentRef = React.createRef()
    }

    convertAssestToEvent(playlistAssetsData) {
        let self = this;

        let events = [];
        playlistAssetsData && playlistAssetsData.map((item) => {
            let obj = {
                title: item.playlistname,
                // start: new Date(item.startTime),
                id: item.assetid,
                imageurl: item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration
            }
            events.push(obj)
        })

        this.setState({ events });
    }


    convertRecordingsToEvent(assets) {
        let self = this;

        let recordingAssets = [];
        assets && assets.map((item) => {
            let obj = {
                title: item.assetname,
                // start: new Date(item.startTime),
                id: item.assetid,
                imageurl: item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                totalduration: item.totalduration
            }
            recordingAssets.push(obj)
        })

        this.setState({ recordingAssets });
    }

    componentDidMount() {
        let self = this;
        const { assetCount, pageNumber } = this.state;
        let userCode = localStorage.getItem('userCode') || '';
        let payload = { assetType: ['Standalone', 'Recording'], assetCount, pageNumber, }
        this.props.getAssets(payload);
        let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers' }

        this.props.playlistAssets(playlistPayload);
        // this.convertAssestToEvent();

        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                if (self.state.calenderId) {
                    let title = eventEl.getAttribute("title");
                    let duration = eventEl.getAttribute("data-for");
                    let isDuration = duration === null ? '' : (' -' + duration)
                    title = title && title.split('-');
                    title = title[0] + isDuration
                    let id = eventEl.getAttribute("data");
                    return {
                        title: title,
                        id: id,
                    };
                } else {
                    // console.log("schedule required !")
                    self.setState({ errorMsg: 'Schedule name required!', showAlert: true })
                }


            },
            eventOverlap: function (stillEvent, movingEvent) {
                return stillEvent.allDay && movingEvent.allDay;
            },
        });
        let scheulesBody = { "activity": "fetch", }
        this.props.manageSchedules(scheulesBody);

    }


    s3CalendarObject = (item) => {
        let fDuration = item.formatedduration ? item.formatedduration : ''
        let obj = {
            title: item.title,
            start: item.start,
            end: item.end,
            classNames: item.classNames ? item.classNames : [],
            extendedProps: {
                envoiAssetId: (item.extendedProps || {}).envoiAssetId,
                formatedDuration: fDuration,
                calenderId: item.calenderId,
                userId: item.userid ? item.userid : user_id,
                appName: item.appname ? item.appname : window.app.appName
            },
            durationEditable: false
        }
        return obj
    }

    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.manageSchedulingData !== this.props.manageSchedulingData && self.state.activity != 'create') {
            let manageSchedulingData = (this.props.manageSchedulingData || []);
            let calendarEvents = [];
            manageSchedulingData && manageSchedulingData.map((item) => {
                let obj = self.s3CalendarObject(item)
                calendarEvents.push(obj)
            })
            console.log("calendarEvents1", calendarEvents)
            if (manageSchedulingData) {
                console.log("calendarEvents2", calendarEvents)
                self.setState({ calendarEvents, eventsData: calendarEvents });
            }
        }
        if ((prevProps.manageSchedulesData !== this.props.manageSchedulesData)) {
            let manageSchedulesData = (this.props.manageSchedulesData || []);
            self.setState({ schedulesData: manageSchedulesData });
            if (manageSchedulesData.length > 0) {
                let body = { "activity": "fetch", calenderId: manageSchedulesData[0].scheduleId }
                this.setState({ calenderId: manageSchedulesData[0].scheduleId })
                this.props.manageScheduling(body);
            }
        }
        if (prevProps.assets != this.props.assets) {
            let assetsData = ((this.props.assets || {}).data || []);
            let assetTotal = (this.props.assets || {}).assetTotal || '';
            let noOfAssets = (this.state.noOfAssets + assetsData.length);
            this.convertRecordingsToEvent(assetsData)
            this.setState({ assetsData, assetTotal, noOfAssets, isLoading: false });
        }

        if (prevProps.playlistAssetsData != this.props.playlistAssetsData) {
            let playlistAssetsData = (this.props.playlistAssetsData || []);
            let assetTotal = (this.props.playlistAssetsData || {}).assetTotal || '';
            let noOfAssets = (this.state.noOfAssets + playlistAssetsData.length);
            this.setState({ playlistAssetsData, isLoading: false })
            this.convertAssestToEvent(playlistAssetsData)
        }
        if (prevProps.scheduleEventsData != this.props.scheduleEventsData) {
            this.setState({ isLoading: false, errorMsg: 'Schedule saved successfully', showAlert: true });
        }

        if (prevProps.playbackUrls != this.props.playbackUrls) {
            let playbackUrlsData = (this.props.playbackUrls || {});
            this.setState({ playbackUrlsData })
            let playerUrl = '';
            if (playbackUrlsData) {
                let playerUrls = playbackUrlsData;
                if (playerUrls && playerUrls.playback) {
                    let playbackUrls = playerUrls.playback;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
                    }
                }
                // console.log("playerUrls", playerUrls)
                //Added this to play for previous object 
                if (!playerUrl) {
                    playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                    // console.log("playerUrls22", playerUrl)
                }
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }

    }




    loadMore(number, loader) {
        let self = this;
        let pageNumber = number ? number : self.state.pageNumber + 1;
        let assetCount = self.state.pageCount;
        self.setState({
            loadbtn: !loader ? "Loading..." : this.state.loadbtn,
            loading: loader,
            pageNumber: pageNumber,
        });
        let appSettings = window.app || {};
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem("token");
        let siteSettings = window.site || {};
        let envoiSubmissionApiBaseUrl = siteSettings.submissionApiBaseUrl;
        let url =
            envoiSubmissionApiBaseUrl +
            "/asset?appname=" +
            window.app.appName +
            "&token=" +
            token +
            "&assetcount=" +
            assetCount +
            "&pageNumber=" +
            pageNumber +
            "&env=" +
            window.app.appEnvName;
        // let userCode = localStorage.getItem("userCode") || "";
        // if (userCode) url += "&code=" + userCode;
        axios
            .post(url, { assetType: ['Standalone', 'Recording'] })
            .then((response) => {
                self.setState({ assetsData: number ? [] : self.state.assetsData, isLoading: false });
                if (
                    response.data &&
                    response.data.result &&
                    response.data.result.data &&
                    response.data.result.data.length > 0
                ) {
                    asyncLoop(
                        response.data.result && response.data.result.data,
                        function (item, next) {
                            self.setState(
                                {
                                    assetsData: self.state.assetsData.concat(item),
                                    loading: false,
                                    loadbtn: !loader ? "Load More" : this.state.loadbtn,
                                    isLoading: false,
                                },
                                function () {
                                    this.convertRecordingsToEvent(self.state.assetsData)
                                    setTimeout(function () {
                                        next();
                                    }, 100);
                                }
                            );
                        }
                    );
                    let newNoOfAssets = response.data.result.data.length;
                    self.setState({
                        noOfAssets: number
                            ? newNoOfAssets
                            : self.state.noOfAssets + newNoOfAssets,
                        loading: false,
                    });
                } else {
                    self.setState({
                        assetTotal:
                            self.state.noOfAssets + response.data.result.data.length,
                        loading: false,
                    });
                }
            })
            .catch((err) => { });
    }

    /**
   * when we click on event we are displaying event details
   */
    eventClick = eventClick => {
        let self = this;
        let payload = { assetId: eventClick.event.id ? eventClick.event.id : (eventClick.event.extendedProps || {}).envoiAssetId }
        this.getAssetDetails(payload);
        this.setState({ loader: true })
    };


    getAssetDetails(payload) {
        let self = this;
        axios.post(envoiSubmissionApiBaseUrl + '/asset?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&assetid=' + payload.assetId)
            .then((response) => {
                if (response && response.data && response.data.result) {
                    let assetData = ((response.data.result || {}).data || [])[0] || {};
                    self.setState({ assetData, isOpen: true, loader: false });
                }
            })
            .catch(error => error)
    }




    eventReceive = info => {
        let self = this;
        let assetName = info.event.title;
        let fullAssetName = assetName && assetName.split('-');
        let title = fullAssetName[0]
        let assetId = info.event.id;
        let start = info.event.start;
        let duration = fullAssetName.slice(-1)[0];
        let assetDuration = info.event.groupId;
        // console.log("start", start)
        if (self.state.calenderId) {
            self.setState({ isMetaDataReady: true, assetInfo: info.event || {} })
            let eventsData = self.state.activity = 'create' ? [...self.state.eventsData] : [...self.state.calendarEvents];
            let payload = { assetId: assetId }
            this.props.getPlaybackUrls(payload);
            let body = {
                title: title,
                start: start,
                end: start,
                formatedduration: duration,
                classNames: ['standaloneAsset'],
                extendedProps: {
                    envoiAssetId: assetId,
                },
                durationEditable: false,
                activity: "create",
                type: 'Scheduler',
                calenderId: self.state.calenderId
            }
            // let body = { "activity": "create", start: new Date(start), channelid: this.state.channelId, "assetid": assetId, "image": "https://vlive.imgix.net/screening/images/portrait_logo.jpg", "assetname": assetName }
            self.props.manageScheduling(body);
            let obj = this.s3CalendarObject(body);
            eventsData.push(obj);
            this.setState({ activity: 'create', eventsData })
        } else {
            // console.log("schedule required !")
            this.setState({ errorMsg: 'Schedule name required!', showAlert: true })
        }
    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    searchOnChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        const { assetCount, pageNumber } = this.state;

        this.setState({ [name]: value });
        if (value == '') {
            if (name == 'playlistSearchInput') {
                let playlistPayload = { isActive: true, type: 'envoiPlaylistByUsers' }
                this.props.playlistAssets(playlistPayload);
            } else {
                let payload = { assetCount, pageNumber, }
                this.props.getAssets(payload);
            }
        }
    }


    searchForAssets(type) {
        const { assetCount, pageNumber } = this.state;
        if (type == 'recordings') {
            this.setState({ recordingAssets: [] });
            let payload = { search: this.state.recodingsSearchInput, assetCount, pageNumber }
            this.props.getAssets(payload);
        } else {
            this.setState({ events: [] });
            let payload = { isActive: true, type: 'envoiPlaylistByUsers', search: this.state.playlistSearchInput }
            this.props.playlistAssets(payload);
        }

    }

    handleKeyDown(e, type) {
        console.log("e", e)
        if (e.key === 'Enter') {
            this.searchForAssets(type);
        }

    }


    calederDataRange = (e) => {
        if (e && e.length > 0) {
            let startDate = moment(e[0]).format('yyyy-MM-DD');
            let endDate = moment(e[1]).format('yyyy-MM-DD');
            this.setState({
                validRange: {
                    start: startDate,
                    end: endDate,
                }
            })
        } else {
            this.setState({
                validRange: {
                }
            })
        }
    }

    addScheduleOpen = (e) => {
        this.setState({
            isAddScheduleOpen: true
        });

    }

    onChangeCalenderName(e) {
        let name = e.target.name;
        let value = e.target.value;
        let errors = Object.assign({}, this.state.errors);
        delete errors[name];
        this.setState({
            [name]: value,
            errors
        });

        if (name == 'calenderId') {
            let body = { "activity": "fetch", calenderId: value }
            this.props.manageScheduling(body);
        }
    }

    addSchedule = (e) => {
        if (!this.validate()) {
            let body = {
                title: this.state.calenderName,
                activity: 'create'
            }
            this.props.manageSchedules(body);
            this.setState({ isAddScheduleOpen: false })
        }

    }

    validate() {
        let isError = false;
        let errors = {};
        console.log("this.state.calenderName", this.state.calenderName)
        if (this.state.calenderName == undefined || this.state.calenderName == '') {
            isError = true;
            errors.calenderName = "Name Required";
        }
        console.log("errors", errors)
        this.setState({ errors });
        return isError;
    }

    // remove = ({ event, jsEvent, view: { calendar } }) => {
    //     console.log("Remove me");
    // };

    // add = ({ start, end, view: { calendar } }) => {
    //     calendar.addEvent({ start, end });
    // };

    scheduleData() {
        let self = this;
        let id = this.state.calenderId
        let payload = {
            schedule: {
                events: self.state.activity == 'create' ? self.state.eventsData : this.state.calendarEvents
            }
        }
        this.props.scheduleEventsToS3(id, payload)
        this.setState({ isMetaDataReady: false })
    }

    render() {
        let self = this;
        const { assetData, schedulesData, recordingAssets, isLoading, noOfAssets, assetTotal, loadbtn, assetInfo } = self.state;
        const { genre, carousel, maturityrating, released, tags, cast, crew, writer, distributer, award, studio } = assetData
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;

        let fullTilte = assetInfo && assetInfo.title && assetInfo.title.split('-');
        let eventTitle = Array.isArray(fullTilte) && fullTilte[0];
        let runtime = Array.isArray(fullTilte) && fullTilte[1];
        console.log("recordingAssets", recordingAssets)
        return (
            <React.Fragment>
                <Header />
                <div className="animated fadeIn demo-app scheduling">
                    <div className='top_bar'>
                        <div className='dsp_flex'>
                            <div className='select_chnl'>
                                <select className="caleder_select" value={this.state.calenderId} placeholder="Calendar" name="calenderId" onChange={e => this.onChangeCalenderName(e)}>
                                    <option >SCHEDULE</option>
                                    {schedulesData && schedulesData.length > 0 && schedulesData.map(function (schedule, i) {
                                        return (
                                            <option key={i} value={schedule.scheduleId}>{schedule.title}</option>
                                        )
                                    })}
                                </select>
                                <span className="material-icons arrow"> arrow_drop_down </span>
                            </div>
                            <button onClick={(e) => this.addScheduleOpen(e)} className="add"><span className="material-symbols-outlined">
                                add
                            </span>Add</button>
                        </div>
                    </div>

                    <div className='fc_col'>
                        <div
                            id="external-events"
                            style={{
                                padding: "10px",
                                width: "80%",
                                height: "auto",
                                maxHeight: "-webkit-fill-available"
                            }}
                        >
                            <CardHeader className="sub_tab">
                                <Nav className="nav-fill card-header-tabs" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '1' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('1');
                                            }}>
                                            Media
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={self.state.activeTab === '2' ? 'active' : ''}
                                            onClick={() => {
                                                this.toggle('2');
                                            }}>
                                            Playlists
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={self.state.activeTab}>
                                <TabPane tabId="1" >
                                    <CardBody>



                                        <div className="lf_search">
                                            <input type="search" className="input" name='recodingsSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'recordings')}
                                                value={this.state.recodingsSearchInput}
                                                placeholder="Search"
                                            />

                                            <button type="button" className="icon" onClick={() => self.searchForAssets('recordings')} ><span className="material-icons">
                                                search
                                            </span></button>
                                        </div>
                                        {this.props.loading && <Loader />}
                                        {!this.props.loading &&
                                            recordingAssets &&
                                            Array.isArray(recordingAssets) &&
                                            recordingAssets &&
                                            recordingAssets.length > 0 ? (
                                            recordingAssets.map((event, i) => (
                                                imgUrl = event.imageurl,
                                                eventFormatDuration = event.totalduration ? event.totalduration : '',
                                                <div
                                                    className="fc-event drag_item"
                                                    title={event && event.title}
                                                    data={event && event.id}
                                                    data-for={eventFormatDuration}
                                                    key={i}
                                                >
                                                    <div className='img'>
                                                        <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                    </div>

                                                    <div className='channel_title'>
                                                        {event && event.title}
                                                    </div>

                                                    <div className='sdl_duration'>{eventFormatDuration}</div>
                                                </div>
                                            ))

                                        ) : (
                                            isLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div className="warning-wrapper empty-result">
                                                        <div className="error-text">
                                                            <h2 className="text-center">Sorry !</h2>
                                                            <p>There are no assets available</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        )}
                                        {noOfAssets < assetTotal && (
                                            <div className="text-center clearfix">
                                                <div
                                                    onClick={() => this.loadMore(undefined, false)}
                                                    className="btn_load"
                                                    type="button"
                                                    id="myButton"
                                                    value=" "
                                                >
                                                    {loadbtn}
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>

                                </TabPane>
                                <TabPane tabId="2" >
                                    <CardBody>

                                        <div className="lf_search">
                                            <input type="search" className="input" name='playlistSearchInput' onChange={e => this.searchOnChange(e)} onKeyDown={(e) => this.handleKeyDown(e, 'playlists')}
                                                value={this.state.playlistSearchInput}
                                                placeholder="Search"
                                            />
                                            <button type="button" className="icon" onClick={() => self.searchForAssets('playlists')} ><span className="material-icons">
                                                search
                                            </span></button>
                                        </div>
                                        {this.state.events && this.state.events.map((event, i) => (
                                            imgUrl = event.imageurl,
                                            eventFormatDuration = event.totalduration ? (" - " + event.totalduration) : '',
                                            // <>
                                            //     <div className="fc-event drag_item pl_item" title="Kiran playlist">
                                            //         <div className='icon' onClick={() => this.handlePlayistById()}><span class="material-icons"> remove_circle_outline </span></div>
                                            //         <div className="img">
                                            //         <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                            //         </div>
                                            //         <div className="channel_title">{event && event.title}</div>

                                            //     </div>
                                            //     <div className='pl_sub'>
                                            //         <div className="fc-event drag_item pl_item" title="Kiran playlist">
                                            //             <div className='icon'><span class="material-icons"> add_circle_outline</span></div>
                                            //             <div className="img">
                                            //                 <img src="https://envoi-common-resources.imgix.net/Envoi/screening/images/landscape_logo.jpg?auto=compress,format" />
                                            //             </div>
                                            //             <div className="channel_title">Kiran playlisttt</div>
                                            //             <div className="sdl_duration"></div>
                                            //         </div>
                                            //         <div className="fc-event drag_item pl_item" title="Kiran playlist">
                                            //             <div className='icon'><span class="material-icons"> add_circle_outline</span></div>
                                            //             <div className="img">
                                            //                 <img src="https://envoi-common-resources.imgix.net/Envoi/screening/images/landscape_logo.jpg?auto=compress,format" />
                                            //             </div>
                                            //             <div className="channel_title">Kiran playlisttt</div>
                                            //             <div className="sdl_duration"></div>
                                            //         </div>
                                            //     </div>
                                            // </>
                                            <div
                                                className="fc-event drag_item"
                                                title={event && event.title}
                                                data={event && event.id}
                                                data-for={event && event.totalduration}
                                                key={i}
                                            >
                                                <div className='img'>
                                                    <img src={event && event.imageurl ? event.imageurl + siteConfig.imageSuffix : ""} />
                                                </div>

                                                <div className='channel_title'>
                                                    {event && event.title}
                                                </div>
                                                <div className='sdl_duration'>{eventFormatDuration}</div>

                                            </div>
                                        ))}

                                    </CardBody>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className="demo-app-calendar" id="mycalendartest">
                            <DateRangePicker onChange={this.calederDataRange} />
                            <FullCalendar
                                // timeZone='America/New_York'
                                id='fullCalendar'
                                defaultView="dayGridMonth"
                                header={{
                                    left: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    center: "title",
                                    right: "prev,next today"

                                }}
                                rerenderDelay={10}
                                eventDurationEditable={false}
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    // second: '2-digit',
                                    hour12: true
                                }}

                                editable={true}
                                droppable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={this.calendarComponentRef}
                                weekends={this.state.calendarWeekends}
                                events={this.state.calendarEvents}
                                // eventDragStop={this.remove}
                                // select={this.add}
                                eventDrop={this.eventDrop}
                                eventReceive={this.eventReceive}
                                eventClick={this.eventClick}
                                validRange={this.state.validRange}

                            />
                            {self.state.calenderId && <div className="cl_buttons">
                                <div className="form_buttons">
                                    {/* <button type="button" className="btn btn-link" onClick={() => self.backToChannels()}>cancel</button> */}
                                    <button type="button" className="btn btn-primary" onClick={e => self.scheduleData(e)} disabled={self.state.executing}><span>{self.state.submitText ? "Saving..." : "Schedule"}</span></button>
                                </div>
                            </div>}
                        </div>
                        <Modal
                            isOpen={this.state.isOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='metadata'>
                                <div className='meta_left'>
                                    <div className='section'>
                                        <h3 className='title'>{assetData.assetname}</h3>
                                        <p>{assetData.description}</p>
                                        <h4 className='mt-10 mb-0'>Asset Duration <span>{assetData.totalduration || '--'}</span></h4>
                                    </div>
                                    <div className='section border_n'>
                                        <h4>Type {carousel && carousel.length > 0 ? carousel.map((item, i) => (<span key={i}>{item.name || 'NA'},</span>)) : <span>NA</span>}</h4>
                                        <h4>Genre {genre && genre.length > 0 ? genre.map((item, i) => (<span key={i}>{item || 'NA'},</span>)) : <span>NA</span>}</h4>
                                        <h4>Release Date <span>{released || '--'}</span></h4>
                                        <h4>Rating  <span>{maturityrating || 'NA'}</span></h4>
                                        {/* <select name="cars" id="cars" className='form-control'>
                                            <option value="11">External ID(s)</option>
                                        </select> */}
                                        {/* <select name="cars" id="cars" className='form-control'> 
                                            <option value="11">Content Right(s)</option>
                                        </select> */}
                                    </div>
                                </div>
                                <div className='meta_right'>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Metadata</a>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pattern</a>
                                        </li> */}
                                        {/* <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Linked Collection</a>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="inputgroup">
                                                <p className="label">Cast</p>
                                                {cast && <SelectionsExample options={[]} className="custom-select form-control" selected={cast || []} placeholder='Cast' labelKey='cast' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Crew</p>
                                                {crew && <SelectionsExample options={[]} className="custom-select form-control" selected={crew || []} placeholder='Crew' labelKey='crew' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Writer</p>
                                                {writer && <SelectionsExample options={[]} className="custom-select form-control" selected={writer || []} placeholder='Writer' labelKey='item' isView={true} />}
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Distributer</p>
                                                {distributer && <SelectionsExample options={[]} className="custom-select form-control" selected={distributer || []} placeholder='Distributer' labelKey='item' isView={true} />}
                                            </div>

                                            <div className="inputgroup">
                                                <p className="label">Awards</p>
                                                {award && <SelectionsExample options={[]} className="custom-select form-control" selected={award || []} placeholder='Awards' labelKey='item' isView={true} />}
                                            </div>

                                            <div className="inputgroup">
                                                <p className="label">Studio</p>
                                                {studio && <SelectionsExample options={[]} className="custom-select form-control" selected={studio || []} placeholder='Studio' labelKey='item' isView={true} />}
                                            </div>

                                            <div className='inputgroup'>
                                                <p className="label"><span>Tags</span></p>
                                                {tags && <SelectionsExample options={[]} className="custom-select form-control" selected={tags || []} placeholder='Tags' labelKey='item' isView={true} />}
                                            </div>
                                            {/* <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div> */}
                                        </div>
                                        {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                        {/* <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                            <div className="inputgroup">
                                                <p className="label">Channel Title</p>
                                                <input type="text" placeholder="Channel Title" label="channel-title" name="channelname" /><span className="errormsg"></span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={this.state.isAddScheduleOpen}
                            style={customStyles}
                            ariaHideApp={false}
                            onRequestClose={() => { this.setState({ isAddScheduleOpen: false }) }}
                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup">
                            <div className='popup add-schedules'>
                                <div className='popup_hed'>
                                    <h2>Add Channel</h2>
                                    <a href="#" onClick={() => { this.setState({ isAddScheduleOpen: false }) }} className='close material-symbols-outlined'>close </a>
                                </div>
                                <div className='popup_content'>
                                    <div className="dsp_flex add_calender">
                                        <input type="text" className="input" name='calenderName' onChange={e => this.onChangeCalenderName(e)} value={this.state.calenderName} placeholder="Enter Name" />
                                        <span className="errormsg" >{this.state.errors.calenderName}</span>
                                        <button type="button" className="add" onClick={e => self.addSchedule(e)} > <span className="material-symbols-outlined">add</span> Add</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                        <SweetAlert show={this.state.showAlert}
                            custom
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={self.state.errorMsg}
                            onConfirm={e => self.setState({ errorMsg: '', showAlert: false })}
                        >
                        </SweetAlert>

                        {self.state.isMetaDataReady && <div className='side_popup'>
                            <div className="popup_hed"><h2>Schedule Media</h2><a href="#" onClick={e => self.setState({ isMetaDataReady: false })} className="close material-symbols-outlined">close </a></div>
                            <div className='popup_content'>
                                <div className='field'>
                                    <label>Asset Title</label>
                                    <p>{eventTitle}</p>
                                </div>
                                <div className='field'>
                                    <label>Asset Runtime</label>
                                    <p>{runtime}</p>
                                </div>
                                {/* <div className='field'>
                                    <label>Target Duration</label>
                                    <div className='dsp_flex'>
                                        <label className="radio_btn">Flexible
                                            <input type="radio" checked="checked" name="radio" />
                                            <span className="check_mark"></span>
                                        </label>
                                        <label className="radio_btn">Fixed
                                            <input type="radio" name="radio" />
                                            <span className="check_mark"></span>
                                        </label>
                                    </div>

                                    <p className='note'>Note: The episode duration will be based on the asset runtime & Ad break duration</p>
                                </div> */}
                                <div className='field preview_block'>
                                    <label>Preview</label>
                                    <div className='video' style={{ width: '100%', height: '50%' }}>
                                        {this.state.playbackUrlsData && <VideoPlayer
                                            videoStream={this.state.playbackURL || ''}
                                            eventData={this.state.playbackUrlsData}
                                            playerType="bitmovin"
                                            item={this.item || {}}
                                            title={this.title || ''}
                                            closePopup={this.closePopup}
                                        // playerTimeChanged={this.playerTimeChanged}
                                        />}
                                    </div>
                                </div>
                                <div className='field'>
                                    <div className='dsp_flex'>
                                        <div className='time'>
                                            <label>Start</label>
                                            <input type="text" className='input' value={assetInfo.start} />
                                            {/* <p className='hours'>HH : MM : SS</p> */}
                                        </div>
                                        <div className='time'>
                                            <label>End</label>
                                            <input type="text" className='input' value={assetInfo.start} />
                                            {/* <p className='input_value'>{assetInfo.start}</p> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='field'>
                                    <div className='dsp_flex'>
                                        <div className='time'>
                                            <label>Scheduling Date*</label>
                                            <input type="date" className='input' value="Jul 13, 2022" />

                                        </div>

                                    </div>
                                </div> */}
                                {/* <div className='field'>
                                    <a href="#">ADD REPEAT</a>
                                </div> */}
                                <div className='pop_footer'>
                                    {/* <p className='note'>All fields marked with * are required</p> */}
                                    <div className='btns'>
                                        <button className='btn' onClick={e => self.setState({ isMetaDataReady: false })}>Cancel</button>
                                        <button className='btn submit' onClick={e => self.scheduleData(e)} >Schedule</button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <Footer />
            </React.Fragment >
        )

    }
}
const mapState = ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls }) => ({ loading, manageSchedulingData, manageSchedulesData, assets, playlistAssetsData, scheduleEventsData, playbackUrls });
const mapDispatch = { manageScheduling, manageSchedules, getAssets, playlistAssets, scheduleEventsToS3, getPlaybackUrls };
export default withRouter(connect(mapState, mapDispatch)(Scheduling));