import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getWebsiteDefault, getUser } from '../../redux/reducers/reducer';
import $ from 'jquery';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { UserContext } from '../userContext';
import Loader from '../Loader';
import EnvoiRetina from '../../assets/EnvoiRetina.png';
import logo from '../../assets/logo.png';
class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            isDisableLoginButtons: false,
            loading: false,
        }

    }

    static contextType = UserContext;

    componentDidMount() {
        let appStorage = window.app.storage;
        // let siteSettings = window.site;
        // let siteConfig = siteSettings.config;
        let appName = window.app.appName;
        if (!appName) {
            this.setState({ isDisableLoginButtons: true })
        }
        window.token = appStorage.getItem('token');
        if (window.token) {
            this.props.getUser(window.token);
        }
        // if (siteConfig.analytics.segment && window.siteConfig.analytics.segment.enable) {
        //     let options = {
        //         "pageName": this.constructor.name,
        //         "appName": siteConfig.appName,
        //         "path": window.location.pathname,
        //         "search": window.location.search,
        //         "referrer": window.location.href,
        //         "url": window.location.href,
        //         "UserEmail": site.localStorage.getItem("email"),
        //     }
        //     window.analytics.page(options);
        // }
    }
    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.userInfo !== this.props.userInfo) {
            localStorage.setItem("envoiId", this.props.userInfo.envoiId || '');
            if (this.props.userInfo.usertype) {
                localStorage.setItem("usertype", this.props.userInfo.usertype);
                localStorage.setItem("userid", this.props.userInfo.userid);
            }
            if (this.props.userInfo.userid) {
                localStorage.setItem("userid", this.props.userInfo.userid);
            }
            if (this.props.userInfo && this.props.userInfo.status === "PENDING REGISTRATION") {
                self.props.history.push("/create");
            } else if (window.token) {
                self.props.history.push("/scheduler");
            }
        }
    }

    closeLock(e) {

        let appStorage = window.app.storage;
        let siteSettings = window.site || {};
        let siteConfig = siteSettings.config || {};
        let showDiv = appStorage.getItem("showDiv");
        let removeHidden = appStorage.getItem("removeHidden");

        $("#" + removeHidden).addClass("hidediv")
        $("." + showDiv).show();

        if (window.oktaLogin) {
            window.oktaWidget.hide();
        } else if (siteConfig.authentication && siteConfig.authentication.key === "auth0") {
            window.signinLock.hide();
            if (window.signupLock) window.signupLock.hide();
        }
    }

    isLoaderUpdated = (status) => {
        this.setState({ loading: status })
    }
    render() {
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        //let defaultbgimage = siteConfig.defaultLandscape;
        // let bgimage;
        // if(defaultbgimage){
        //     bgimage = {
        //         backgroundImage: "url(" +  defaultbgimage  + ")"
        //       };
        // }
        if (!siteConfig.envoiScheduler) {
            siteConfig.envoiScheduler = {
                landingPage: {
                    backgroupImage:
                        "https://envoi-common-resources.imgix.net/Envoi/submission/images/landscape_background.jpg",
                    logo: "https://envoi-common-resources.imgix.net/Envoi/submission/images/landingpagelogo.png?auto=compress",
                    envoiRetinaImage:
                        "https://envoi-common-resources.imgix.net/Envoi/screening/images/scheduler_retina.png?auto=compress",
                    title: "Scheduler",
                    description: "Scheduler for TV, Film and Media Agencies",
                },
            };
        }
        let imagesAssetResourcesPrefix = siteConfig.commonResourcesUrl + "screening/";
        let envoiScheduler = siteConfig.envoiScheduler || {};
        return (
            // style={{ backgroundImage: 'url(' + siteConfig.envoiScheduler.landingPage.backgroupImage + ')' }}
            <div className="jp_landing bg_img" >
                {/* <div className="overlay"></div> */}
                {!this.state.isDisableLoginButtons && <div id="authlogindiv" className="steps hidediv">
                    <img alt="Auth0" src={window.site.auth0Logo} className="u-logo-img" />
                    <div className="stepdesign">
                        <div onClick={e => this.closeLock(e)} className="closebtn" ><span className="material-icons">close</span></div>
                    </div>
                    <div className="u-bl-center">
                        <div id="signupcontainer">
                        </div>
                        <div id="logincontainer">
                        </div>
                        <div id="osw-container"></div>
                    </div>
                </div>}
                {this.state.loading && <Loader />}
                <div className="join_row float_center">
                    <div className="cols">
                        <div className="jp_signinWrapper">
                            <img alt="header" className="wp_logo" src={(envoiScheduler.landingPage || {}).logo} />
                            <div className="jp_descBlock">
                                <h1 className="land_title">{(envoiScheduler.landingPage || {}).title}</h1>
                                <p className="land_subtitle">{(envoiScheduler.landingPage|| {}).description}</p>
                                {/* <h1 className="land_title">Mobile Live Streaming</h1>
                                <p className="land_subtitle">Mobile Streaming for Live Broadcasters</p> */}

                                {!this.state.isDisableLoginButtons && <div className="signup_btns">
                                    <SignUp isLoaderUpdated={this.isLoaderUpdated} labelText="SIGN UP" cssClass="green_filled" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv" buttonHide={false} />
                                    <SignIn isLoaderUpdated={this.isLoaderUpdated} labelText="SIGN IN" cssClass="stroke" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv" buttonHide={false} />
                                </div>}
                            </div>
                            <div className="store-icons">
                                {siteConfig.storeLinks && siteConfig.storeLinks.iTunesAppStore && <a href={siteConfig.storeLinks && siteConfig.storeLinks.iTunesAppStore} onClick={ev => { ev.preventDefault(); }}><img alt="apple" src={imagesAssetResourcesPrefix + "images/common-images/applestore.png"} />
                                </a>}
                                {siteConfig.storeLinks && siteConfig.storeLinks.googleAppStore && <a target="_blank" href={siteConfig.storeLinks && siteConfig.storeLinks.googleAppStore} onClick={ev => { ev.preventDefault(); }}><img alt="tv" src={imagesAssetResourcesPrefix + "images/common-images/google-play.png"} />
                                </a>}
                                {siteConfig.storeLinks && siteConfig.storeLinks.amazonAppStore && <a href={siteConfig.storeLinks && siteConfig.storeLinks.amazonAppStore} target="_blank" >
                                    <img alt="google" src={imagesAssetResourcesPrefix + "images/common-images/amazonfiretv.png"} /></a>}
                            </div>
                        </div>
                    </div>

                    <div className="cols">
                        <img alt="EnvoiRetina" src={(envoiScheduler.landingPage || {}).envoiRetinaImage} className="retina_]]img" />
                    </div>
                </div>
            </div>
        )

    }
}

// export default withRouter(LandingPage);
const mapState = ({ websiteDefaultData, userInfo }) => ({ websiteDefaultData, userInfo });
const mapDispatch = { getWebsiteDefault, getUser };
export default withRouter(connect(mapState, mapDispatch)(LandingPage));
