
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateUserRelation } from '../../redux/reducers/reducer';

class Plans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            error: '',
            errors: {},
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    handleOptionChange(e, plan) {
        let self = this;

        localStorage.setItem('plans', JSON.stringify([plan]));
        localStorage.setItem('productId', plan.planId);
        localStorage.setItem('productId', plan.planId);
        localStorage.setItem('productPrice', plan.price);
        localStorage.setItem('productName', plan.planName);
        localStorage.setItem('productsku', plan.sku);
        localStorage.setItem('productDescription', plan.description ? plan.description : '');
        localStorage.setItem('productDiscount', false);
        let appStorage = window.app.storage;
        // let siteSettings = window.site;
        // let siteConfig = siteSettings.config;
        let appName = window.app.appName;
        if (!appName) {
            this.setState({ isDisableLoginButtons: true })
        }
        window.token = appStorage.getItem('token');
        if (!window.token) {
            window.location = "/";
        } else if (plan && plan.planId == 'free') {
            self.validateRoutes()
        }
        else {
            let userinfo = { status: "PENDING PAYMENT" }
            this.updateStaus(userinfo)
            // window.location = "/payment";
            self.props.history.push("/payment");

        }
    }
    updateStaus(userinfo) {
        let self = this;
        localStorage.setItem("userstatus", userinfo.status);
        self.props.updateUserRelation(userinfo)
    }
    validateRoutes() {
        let userinfo = { status: 'active' }
        this.updateStaus(userinfo)
        return window.location = "/home";
    }

    render() {
        let self = this;
        const { } = self.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        console.log("siteConfig////////w", siteConfig)
        let envoiBroadcast = siteConfig.envoiBroadcast || {};
        let subscriptionPlans = (((envoiBroadcast.subscription || {}).subscriptionPlans || {}).plans) || [];
        let braintreeSettings = siteConfig.paymentGateway || {};
        let currencySymbol = '$';
        if (braintreeSettings.currency) {
            currencySymbol = (braintreeSettings.currency && braintreeSettings.currency.symbol) ? braintreeSettings.currency.symbol : '$';
        }

        return (
            <div className="app-main-content-wrapper">
                <div className="app-name-content">
                    <div className="loader-postn">
                        <div className="create_section">
                            <div className="header_det pmt_dt">
                                <div className="wrapper">
                                    <h1 className="logo">
                                        <img src={window.site.logoImage} /></h1>
                                </div>
                            </div>

                            <div className="bc_contant bc_plans">
                                <div className="plan_wrapper">
                                    <div className="paffMob_plnStep">SELECT A PLAN</div>
                                    {/* <h3 className="hed">EARLY BIRD PRICING</h3>
                                <p className="sub_hed">Until {setLaunchDate}</p> */}
                                    <div className="row justify-center">
                                        {subscriptionPlans.length > 0 && subscriptionPlans.map((plan, j) => {
                                            let planid = plan.planId;
                                            let tab = 'tab' + j;
                                            return (
                                                <div className="col-md-3" key={j}>
                                                    <div className="plan_section">
                                                        <div className="plan_hed">
                                                            <h2>{plan.planName.toUpperCase()}</h2>
                                                            {planid == "free" ?
                                                                <h3>JOIN WAITING LIST</h3> : <h3><sub>{currencySymbol}</sub>{plan.price} {plan.period == '30' ? '/mo' : plan.period == '365' ? '/yr' : ''}</h3>}
                                                        </div>
                                                        {/* <h4>{plan.save}&nbsp;</h4> */}
                                                        {/* <h4>Save 40% off regular price </h4> */}
                                                        <div className="plan_data">
                                                            <ul>
                                                                {plan.description && plan.description.length > 0 && plan.description.map((item, d) => {
                                                                    return (
                                                                        <li key={d}>{item}</li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                            <div className="dsp_flex" style={{ "cursor": "pointer" }}>
                                                                <a className={plan.buttonDisable ? "plan_btn disabled" : "plan_btn"} onClick={e => this.handleOptionChange(e, plan)}>REGISTER</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>)
    }
}
const mapState = ({ }) => ({});
const mapDispatch = { updateUserRelation };
export default connect(mapState, mapDispatch)(Plans);