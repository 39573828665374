/** get location  */
export const location = (name) => {
  let results = new RegExp(name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
    results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  }
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
}

export const removeSpecialCharecters = (filename) => {
  let timeStamp = new Date().getTime();
  let tmpFile = filename
    .substring(0, filename.lastIndexOf("."))
    .replace(/[^a-zA-Z 0-9]/g, "");
  tmpFile = tmpFile.replaceAll(" ", "");
  let tmpExtension = filename.substring(filename.lastIndexOf("."));
  let tmpNewFileName = tmpFile + timeStamp + tmpExtension;
  // console.log("tmpNewFileName", tmpNewFileName)
  // return encodeURIComponent(tmpNewFileName);
  return tmpNewFileName;
};

export const copyToClipboard = (promourl) => {
  const el = document.createElement("input");
  el.value = promourl;
  el.id = "url-input";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  el.remove();
};



let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
