import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as config from '../../config';
import $ from 'jquery';
// import AssetInfoShare from '../AssetInfoShare';
// Styles

let isListenertStated = false;
class VideoPlayer extends Component {

  constructor(props) {
    super (props);
    this.videoElement = React.createRef();
    this.state = {
      maxMetaData: 10,
      metaData: [],
      shareClickPopup: false,
      geoLocation: {},
    }
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    const mediaPlayerScript = document.createElement("script");
    switch(this.props.playerType) {
      case 'bitmovin':
        this.mediaPlayerScriptLoaded();
      break;

      case 'envoi':
      break;

      case 'AWS Interactive Video Player':
        mediaPlayerScript.src = 'https://player.live-video.net/1.2.0/amazon-ivs-player.min.js';
      break;

      default:
        console.warn(`Unknown video player type "${this.props.playerType}"`)
    }
    if (localStorage.getItem("loc")) {
      let locationObj = JSON.parse(localStorage.getItem("loc"));
      let locationData = (locationObj && locationObj.headers) || {};
      this.handleLocation(locationData);
  }
    mediaPlayerScript.async = true;
    mediaPlayerScript.onload = () => this.mediaPlayerScriptLoaded();
    document.body.appendChild(mediaPlayerScript);
  }
  componentDidUpdate(prevProps) {

    if (prevProps.videoStream !== this.props.videoStream && this.props.playerType !== 'envoi') {
      this.mediaPlayerScriptLoaded();
    }else if(prevProps.videoStream !== this.props.videoStream && this.props.eventType){
      this.mediaPlayerScriptLoadedBitmovinPlayer();
}
    

  }
  componentWillUnmount() {
    this.destroyPlayer();
    isListenertStated = true;
  } 
  async handleLocation(locationData) {
    let self = this;
    let geoLocation = {}
    if (Object.keys(locationData).length) {
        geoLocation['city'] = (locationData['cloudfront-viewer-city'] && locationData['cloudfront-viewer-city'][0] && locationData['cloudfront-viewer-city'][0].value) || '';
        geoLocation['region'] = (locationData['cloudfront-viewer-country-region'] && locationData['cloudfront-viewer-country-region'][0] && locationData['cloudfront-viewer-country-region'][0].value) || '';
        geoLocation['country'] = (locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value) || '';
        geoLocation['postalcode'] = (locationData['cloudfront-viewer-postal-code'] && locationData['cloudfront-viewer-postal-code'][0] && locationData['cloudfront-viewer-postal-code'][0].value) || '';
        geoLocation['latitude'] = (locationData['cloudfront-viewer-latitude'] && locationData['cloudfront-viewer-latitude'][0] && locationData['cloudfront-viewer-latitude'][0].value) || '';
        geoLocation['longitude'] = (locationData['cloudfront-viewer-longitude'] && locationData['cloudfront-viewer-longitude'][0] && locationData['cloudfront-viewer-longitude'][0].value) || '';
        self.setState({ geoLocation });
    }
}
  mediaPlayerScriptLoaded = () => {
    switch(this.props.playerType) {
      case 'bitmovin':
        return this.mediaPlayerScriptLoadedBitmovinPlayer();

      case 'AWS Interactive Video Player':
        return this.mediaPlayerScriptLoadedIvsPlayer();

      default:
        return;
    }
  }

  mediaPlayerScriptLoadedBitmovinPlayer = () => {
    // @TODO Get player key and other player configuration from config and/or website defaults

    let bitmovinPlayerConfig = window.site.config.player.bitmovin;
    let bitmovinPlayerKey = config.DEFAULT_BITMOVIN_PLAYER_KEY;
    let bitmovinAnalyticsKey = config.DEFAULT_BITMOVIN_ANALYTICS_KEY;

    console.log('bitAnalyticsKey', bitmovinAnalyticsKey);

    let playerConfig = {
      analytics: {
        videoId: this.props.item.event_id,
        title: this.props.title,
        customData1: window.app.appName,
      },
      playback: {
        autoplay: true,
        muted: true
      },
    };

    if (bitmovinPlayerKey) {
      playerConfig.key = bitmovinPlayerKey;
    }
    if (localStorage.getItem("envoiId")) {
      playerConfig.analytics.customData2 = 'SessionId :' + (localStorage.getItem("envoiId") || '');
    }
    if (localStorage.getItem("userid")) {
      playerConfig.analytics.customData3 = 'UserID :' + (localStorage.getItem("userid") || '');
    }
    if (this.state.geoLocation) {
      playerConfig.analytics.customData4 = {Geolocation: this.state.geoLocation };
    }
    if (bitmovinAnalyticsKey) {
      playerConfig.analytics.key = bitmovinAnalyticsKey;
    }

    const bitmovin = window.bitmovin;
    // const UIFactory = window.bitmovin.

     window.player = new bitmovin.player.Player(this.videoElement.current, playerConfig);
    // UIFactory.buildDefaultUI(player);

    let videoStreamURL = this.props.videoStream;
    window.videoStreamURL = videoStreamURL;
    let videoStreamType = videoStreamURL.endsWith('.m3u8') ? 'hls' : 'progressive';

    let playerSource = {
      [videoStreamType]: this.props.videoStream
    }
    
    window.player.load(playerSource).then(() => {
        this.setState({
            ...this.state,
            player: window.player
        });
        console.log('Successfully loaded source');
    }, () => {
        console.log('Error while loading source');
    });
    let currentTime = window.player.getCurrentTime();
      window.player.on(bitmovin.player.PlayerEvent.TimeChanged, function () {
        if (currentTime !==  window.player.getCurrentTime().toString().split(".")[0]) {
          currentTime =  window.player.getCurrentTime().toString().split(".")[0];
          self.props.playerTimeChanged(currentTime);
      }
        
      });
    let self = this;
    $(document.body).delegate('#bmpui-id-1041', 'click', () => {
      console.log('share clicked 1');
      self.openModal();
  });
 
  }
  openModal() {
    console.log('share clicked 2');
    let self = this;
    self.setState({ shareClickPopup: true });
}
  closeModal() {
    let self = this;
    self.setState({ shareClickPopup: false });
}

  mediaPlayerScriptLoadedIvsPlayer = () => {
    // This shows how to include the Amazon IVS Player with a script tag from our CDN
    // If self hosting, you may not be able to use the create() method since it requires
    // that file names do not change and are all hosted from the same directory.

    const MediaPlayerPackage = window.IVSPlayer;

    const playerOverlay = document.getElementById("overlay");
    const btnPlay = document.getElementById("play");
    const btnMute = document.getElementById("mute");

    // First, check if the browser supports the Amazon IVS player.
    if (!MediaPlayerPackage.isPlayerSupported) {
        console.warn("The current browser does not support the Amazon IVS player.");
        return;
    }

    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    const player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    // Attach event listeners
    player.addEventListener(PlayerState.PLAYING, function () {
      if (config.DEBUG) console.log("Player State - PLAYING");
    });
    player.addEventListener(PlayerState.ENDED, function () {
      if (config.DEBUG) console.log("Player State - ENDED");
    });
    player.addEventListener(PlayerState.READY, function () {
      if (config.DEBUG) console.log("Player State - READY");
    });
    player.addEventListener(PlayerEventType.ERROR, function (err) {
      if (config.DEBUG) console.warn("Player Event - ERROR:", err);
    });
    player.addEventListener(PlayerEventType.DURATION_CHANGED, function (cue) {
      console.log('player duration', player.getDuration())
    });

    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, function (cue) {
      const metadataText = cue.text;
      const position = player.getPosition().toFixed(2);
      if (config.DEBUG) console.log(
        `Player Event - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
      );
    });

    // Setup stream and play
    player.setAutoplay(true);
    player.load(this.props.videoStream);

    // Setvolume
    player.setVolume(0.1);

    if (playerOverlay) {
    // Show/Hide player controls
    playerOverlay.addEventListener(
      "mouseover",
      function (e) {
        playerOverlay.classList.add("overlay--hover");
      },
      false
    );
    playerOverlay.addEventListener("mouseout", function (e) {
      playerOverlay.classList.remove("overlay--hover");
    });
    } 

    // Controls events
    // Play/Pause
    if (btnPlay) {
    btnPlay.addEventListener(
      "click",
      function (e) {
        if (btnPlay.classList.contains("player-btn--play")) {
          // change to pause
          btnPlay.classList.remove("player-btn--play");
          btnPlay.classList.add("player-btn--pause");
          player.pause();
        } else {
          // change to play
          btnPlay.classList.remove("player-btn--pause");
          btnPlay.classList.add("player-btn--play");
          player.play();
        }
      },
      false
    );
    }

    // Mute/Unmute
    if (btnMute) {
    btnMute.addEventListener(
      "click",
      function (e) {
        if (btnMute.classList.contains("player-btn--mute")) {
          btnMute.classList.remove("player-btn--mute");
          btnMute.classList.add("player-btn--unmute");
          player.setMuted(1);
        } else {
          btnMute.classList.remove("player-btn--unmute");
          btnMute.classList.add("player-btn--mute");
          player.setMuted(0);
        }
      },
      false
    );

  }
  }

  destroyPlayer() {
    if (this.state.player != null) {
      this.state.player.destroy();
      this.setState({
          ...this.state,
          player: null
      });
    }
  } 
  
  renderEnvoiPlayer() {
    let self = this;
    if(this.props.eventData){
      let path = window.site.screeningApiBaseUrl+"/bitmovin?appname="+window.app.appName+this.props.eventData
      if(!isListenertStated){
      var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      var eventer = window[eventMethod];
      var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
      // Listen to message from child window
          isListenertStated = true;
          eventer(messageEvent, function (e) {
          var key = e.message ? "message" : "data";
          var data = e[key];
          if(!isNaN(data)){
            self.props.playerTimeChanged(data)
          }
          //run function//
        }, false);
      }
      return <iframe className = "iframePlayer" src={path} title="Envoi Player"></iframe>;
    }else{
      return <div id='video-player' ref={this.videoElement}/>;
    }
  }

  renderBitmovinPlayer() {
     return <div id='video-player' ref={this.videoElement}/>;
    //  <div className="recording_play">
    //    {/* <span className="popup-close" style={{top: 'inherit'}} onClick={this.props.closePopup}></span> */}
    //    <div id='video-player' ref={this.videoElement}/>
    //  {/* <AssetInfoShare  item={this.props.item}
    //  title={this.props.title} closeModal={this.closeModal} shareClickPopup={this.state.shareClickPopup} shareLink={this.props.sharelink} /> */}
    //  </div>;
   }

  renderIvsPlayer() {
    return (
      <div className="player-wrapper pos-relative">
        <div className="aspect-spacer"/>
        <div className="pos-absolute full-width full-height top-0">         
          <video id="video-player" className="el-player" ref={this.videoElement} controls playsInline muted/>
        </div>
      </div>
    )
  }

  render() {
    let playerType = this.props.playerType
    switch(playerType) {
      case 'bitmovin':
        return this.renderBitmovinPlayer();

      case 'AWS Interactive Video Player':
        return this.renderIvsPlayer();

      case 'envoi':
        return this.renderEnvoiPlayer();

      default:
        return null;
    }
  }
}

VideoPlayer.propTypes = {
  eventData: PropTypes.string,
  playerType: PropTypes.string,
  title: PropTypes.string,
  videoStream: PropTypes.string,
  playerTimeChanged: PropTypes.func,
};

export default VideoPlayer;