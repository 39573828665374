import React, { Component } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { browserHistory } from 'react-router';
import * as config from '../config';
import { connect } from 'react-redux';
import { getWebsiteDefault, getLocation } from '../redux/reducers/reducer';
import LandingPage from './authentication/LandingPage';
import { UserProvider } from './userContext';
import AddDevice from "./authentication/AddDevice";
import CreateAnAccount from './authentication/CreateAnAccount';
import ActivationCode from './authentication/ActivationCode';
import AlertPopup from './AlertPopup';
import SuccessBought from './SuccessBought';
import Loader from './Loader';
import SubscriptionPayment from './authentication/SubscriptionPayment';
import Terms from './authentication/Terms';
import Plans from './authentication/Plans';
import Profile from './authentication/Profile';
import Scheduler from './scheduler/index';



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      status: localStorage.getItem('status'),
    }
  }
  componentDidMount() {
    this.props.getWebsiteDefault();
  }
  async componentDidUpdate(prevProps) {
    let nextProps = this.props
    if (nextProps.websiteDefaultData !== prevProps.websiteDefaultData) {
      let siteConfig = window.site.config || {};
      let imageSuffix = siteConfig.imageSuffix;
      let imagePrefix = siteConfig.imagePrefix
      this.setState({ imagePrefix, imageSuffix, siteConfig, isLoaded: true })
      this.props.getLocation()
    }
  }
  render() {
    const { imagePrefix, siteConfig, isLoaded, status } = this.state;
    const baseHref = config.BASE_HREF;
    return (isLoaded ?
      <div className="App full-width full-height envoi-scheduler">
        <UserProvider value={{ imagePrefix, siteConfig }}>
          <Router history={browserHistory}>
            <Switch>
              <Route path="/create" component={CreateAnAccount} />
              <Route path='/adddevice' component={AddDevice} />
              <Route path="/activation" component={ActivationCode} />
              <Route path="/boughtsuccess" component={SuccessBought} />
              <Route path="/subpayment/:planId" component={SubscriptionPayment} />
              <Route path="/terms" component={Terms} />
              <Route path="/plans" component={Plans} />
              <Route path="/profile" component={Profile} />
              <Route path="/scheduler" component={Scheduler} />
              <Route path={`${baseHref}`}>
                <LandingPage
                />
                 {/* <Route path={`${baseHref}`}>
                <Scheduler
                />  */}
              </Route>
            </Switch>
          </Router>
        </UserProvider>
        <AlertPopup />
      </div> : <Loader />
    );
  }
}

const mapState = ({ websiteDefaultData }) => ({ websiteDefaultData });
const mapDispatch = { getWebsiteDefault, getLocation };
export default connect(mapState, mapDispatch)(App);

